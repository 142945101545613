<template>
  <ion-modal
    :is-open="isVisible"
    :initial-breakpoint="1"
    :breakpoints="[0, 1]"
    @didDismiss="closeModal"
  >
    <ion-content class="modal-content">
      <div class="content-wrapper">
        <img src="assets/update_app.png" alt="Update" class="modal-image" />
        <h2>¡Nueva versión disponible!</h2>
        <p>Hemos mejorado la app para hacer tu trabajo aún más fácil. ¡Actualiza ahora y disfruta de todas las novedades!</p>
        <div class="botones">
          <ion-button @click="handleUpdate">Actualizar ahora</ion-button>
          <ion-button class="btn-close" @click="closeModal">Más tarde</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script>
import { isPlatform } from '@ionic/vue';
import { useGmaoStore } from '@/stores/gmao';

export default {
  name: 'UpdateApp',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const gmao = useGmaoStore();

    return {
      gmao,
    };
  },

  methods: {
    closeModal() {
      // Cambia el estado para evitar que se abra hasta mañana
      this.gmao.updateWanted = false; // Permitimos abrir el modal de nuevo
      this.$emit('update:isVisible', false); // Emite un evento al padre para cerrar el modal
    },

    handleUpdate() {
      // Abre la tienda según la plataforma
      const url = isPlatform('ios')
        ? 'https://apps.apple.com/es/app/gmao-cloud-m%C3%B3vil-clientes/id1275471962'
        : 'https://play.google.com/store/apps/details?id=com.tecneca.gmaocloudclientes&hl=es_419';
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="css" scoped>

/* Contenedor del contenido */
.modal-content {
  display: flex; /* Flexbox para organizar el contenido */
  flex-direction: column; /* Coloca los elementos en columna */
  justify-content: space-between; /* Deja el contenido hacia el principio del contenedor */
  align-items: center; /* Centrado horizontal */
  height: 100%; /* Ocupa toda la altura del modal */
  padding: 20px; /* Espaciado interno */
  text-align: center; /* Centra el texto */
  box-sizing: border-box; /* Asegura que el padding no afecte el tamaño */
  position: relative; /* Necesario para centrar */
}

/* Imagen */
.modal-image {
  width: 100%;
  max-width: 300px; /* Limita el tamaño máximo de la imagen */
  border-radius: 15px;
  margin-top: 20%;
}

.botones {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  width: 80%;
  position: absolute; /* Para poder posicionarlo */
  top: 50%; /* Posición desde la parte superior */
  left: 50%; /* Posición desde la izquierda */
  transform: translate(-50%, -50%); /* Centrado exacto usando transformación */
  text-align: center; /* Centrar el texto */
}

/* Texto */
h2 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  text-align: center;
  left: 50%; /* Posición desde la izquierda */
}

p {
  margin-bottom: 25%;
  font-size: 1.1rem;
  text-align: center;
  left: 50%; /* Posición desde la izquierda */
}

/* Botón principal */
ion-button:first-of-type {
  --background: #007bff; /* Azul para el botón principal */
  --color: white; /* Texto blanco */
  --border-radius: 8px; /* Bordes redondeados */
  width: 100%; /* Ancho completo */
  height: 45px; /* Altura del botón */
  font-size: 1rem; /* Tamaño del texto */
}

/* Botón secundario */
ion-button:last-of-type {
  --background: transparent; /* Fondo transparente */
  --color: #007bff; /* Texto azul */
  --box-shadow: none;
  --border-width: 0;
  width: auto; /* Tamaño ajustado */
  font-size: 0.9rem; /* Tamaño del texto */
  text-align: center; /* Centrado del texto */
}

</style>
